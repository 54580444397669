export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const $navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > $navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                $navbar.removeClass('navbar--ontop')
                $navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                $navbar.removeClass('navbar--onscroll')
                $navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const $body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Close menu when opening eco-bar
        $('[data-toggle="#eco-bar"]').on('click', () => {
            $body.removeClass('menu-open').removeClass('submenu-open')

            if ($body.hasClass('has-banner')) {
                NavbarComponent.changeLogoColor('banner')
            }
        })

        //Open/Close menu
        $(trigger).on('click', () => {
            $(submenu).removeClass('active')
            $('#eco-bar').addClass('eco-bar--hidden')

            $body.toggleClass('menu-open').removeClass('submenu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if (body.hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--onscroll').length > 0 || $('body.has-banner').length === 0) {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('active')

            $body.toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('active')

            $body.removeClass('submenu-open')
        })
    }
}
