/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new NavbarComponent()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }

    //Managing scroll reveal for sections
    const sectionViewObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('viewed')
                    entry.target.classList.add('in-view')
                } else {
                    entry.target.classList.remove('in-view')
                }
            })
        },
        { rootMargin: '-15%' },
    )
    document.querySelectorAll('section').forEach((section) => {
        sectionViewObserver.observe(section)
    })

    //Managing scroll reveal for data-appear-order elements & others (the only difference is the rootMargin)
    const dataAppearOrderViewObserver = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('viewed')
                    entry.target.classList.add('in-view')
                } else {
                    entry.target.classList.remove('in-view')
                }
            })
        },
        { rootMargin: '0px' },
    )

    dataAppearOrderViewObserver.observe(document.querySelector('.home'))

    document.querySelectorAll('[data-appear-order]').forEach((section) => {
        dataAppearOrderViewObserver.observe(section)
    })

    $('[data-appear-order]').each(function () {
        $(this).css({ transitionDelay: `${$(this).data('appear-order') * 0.5}s` })
    })
})
